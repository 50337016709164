import React from "react";

interface SideMenuProps {
  onItemClick: (id: string) => void;
  activeId: string;
}

export const menuItems = [
  { id: "5j4cgk", label: "Getting Started" },
  { id: "rxjj4g", label: "Adding Interactivity" },
  { id: "sysqkw", label: "Level Selection" },
  { id: "cy7cnq", label: "Floating Labels" },
  { id: "6lq783", label: "Markers" },
  { id: "fr5n5r", label: "Listing & Search Locations" },
  { id: "4jq9t4", label: "A-B Wayfinding" },
  { id: "turn-by-turn-directions", label: "Turn-by-Turn Directions" },
  { id: "gj45kc", label: "Camera Controls" },
  { id: "ltfwcw", label: "Blue Dot" },
  { id: "stacked-maps", label: "Stacked Maps" },
];

const SideMenu: React.FC<SideMenuProps> = ({ onItemClick, activeId }) => {
  return (
    <div className="side-menu">
      <h2>Web SDK Examples</h2>
      <ul>
        {menuItems.map((item) => (
          <li key={item.id} className={item.id === activeId ? "active" : ""} onClick={() => onItemClick(item.id)}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
