import React from "react";

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="wrapper" id="wrapper">
        <div className="header-site">
          <div className="container-fluid">
            <div className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                </button>
                <div className="navbar-dev">
                  <a className="navbar-brand" href="http://13.212.55.112">
                    <img src="./logo.svg" alt="" />
                  </a>
                  <div className="navbar-divider"></div>
                  <a>
                    <img className="dev-logo" src="./developer-logo.svg" />
                  </a>
                </div>
                <div className="collapse navbar-collapse">
                  <ul className="navbar-nav mb-2 mb-lg-0 px-3">
                    <li className="nav-item">
                      <a className="nav-link active" href="">
                        <span className="txt">Playground</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
