import React, { useState } from "react";
import SideMenu, { menuItems } from "./SideMenu";
import Header from "./Header";

const App: React.FC = () => {
  const [activeId, setActiveId] = useState(menuItems[0].id);

  const handleItemClick = (id: string) => {
    setActiveId(id);
  };

  const iframeSrc = `https://codesandbox.io/embed/${activeId}?view=split&module=%2Fpublic%2Findex.html&theme=light&hidenavigation=1&fontsize=12&editorsize=40&forcerefresh=1`;

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <SideMenu onItemClick={handleItemClick} activeId={activeId} />
        <div className="content">
          <iframe
            src={iframeSrc}
            style={{ width: "100%", height: "100%", border: 0, overflow: "hidden" }}
            title={activeId}
            allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
            sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default App;
